export const SERVER_API_URL = 'https://api.alefeats.com' // Production
export const SERVER_WEBSOCKET_HOST = 'socket.alefeats.com';
export const SERVER_WEBSOCKET_PORT = 6001;
export const SERVER_PUSHERAPP_ID = '1659688';
export const SERVER_PUSHER_APP_KEY = '5fb5f7bdfadf4480d158';
export const SERVER_PUSHER_APP_SECRET = '8a4d8360d7abaed2d94f';
export const SERVER_PUSHER_APP_CLUSTER = 'ap1';

export const CLIENT_ID = '3';
export const CLIENT_SECRET = '8bvyVCxPF9IcZDV43FT3acAuBXQj2IR40XKtWgnF';

export const SERVER_WEBSOCKET_URL = `wss://${SERVER_WEBSOCKET_HOST}:${SERVER_WEBSOCKET_PORT}/socket`;

export const PAGINATION_OPTIONS = {
    defaultPageSize: 50,
    showSizeChanger: true,
    pageSizeOptions: ['10', '25', '50', '100', '200', '500', '1000']
}